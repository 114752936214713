import React, { useState,  } from 'react';
import { useNavigate } from "react-router-dom";
import { auth, login } from "../utils/firebase";


function Login() {
  const navigate = useNavigate();
 const [email, setEmail] = useState("");
 const [password, setPassword] = useState("");

  const handleLogin = () => {
    login(auth, email, password)
        .then((userCredential) => {
            const user = userCredential.user;
            navigate("/admin");
        })
        .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            alert("Error, Please try again later!")
        });
  };

  return (
    <div>
      <h1 style={{color:"white"}}>Login</h1>
      <input type="text" placeholder="email" onChange={(e)=>setEmail(e.target.value)}/><br/><br/>
      <input type = "password" placeholder="password" onChange={(e)=>setPassword(e.target.value)}/><br/><br/>
      <button onClick={handleLogin}>Log in</button>
    </div>
  );
};
export default Login;
