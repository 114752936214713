import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from "react-router-dom";
import styled from "@emotion/styled";
import { collection, addDoc,updateDoc ,query, where, getDocs, doc, getDoc} from "firebase/firestore"; 
import {db} from "../utils/firebase.js";
import QRCode from 'qrcode';

import redLogo from "../assets/logo-red.png";

const Container = styled.div`
  height: 100vh;
  width: 100%;
  font-family: Cygnito Mono;
`;
const View = styled.div`
  height: 600px;
  max-width: 100%;
  margin: 25px;
  border-style: solid;
  border-color: #e63731;
  text-align: center;
`;
const Nav = styled.nav`
  padding-top: 30px;
  width: 100%;
  max-width: 1281px;
  margin: auto;
`;
const NavItems = styled.span`
  color: #e63731;
`;
const Logo = styled.img`
  width: 100px;
  max-width: 100%;
`;

const TerminalContent = styled.div`
  background-color: black;
  text-align:center;
  color: #e63731;
  padding-left: 20px;
`;
const ButtonGroup = styled.div`
  display: flex;
`;
const ButtonSection = styled.div`
  flex: 1;
`;

const Button = styled.button`
  background-color: #e63731;
  font-family: Cygnito Mono;
  font-size: 18px;
  border: none;
  padding: 5px 15px 5px 15px;
  margin-left: 5px;
  cursor: pointer;
`;
const TerminalInput = styled.input`
  background-color: black;
  color: #e63731;
  font-family: Cygnito Mono;
  border: none;
`;
const ThankYou = styled.h2`
	margin-top:150px;
	font-size:36px;
	line-height:1;
`;


function PrintOrder() {
	const navigate = useNavigate();
	const [filmIDs, setFilmIDS] = useState([]);
	const [QRCodes, setQRCodes] = useState([]);

	const generateQR = async text => {
  	try {
    	return await QRCode.toDataURL(text)
  	} catch (err) {
    	console.error(err);
  	}
}

	useEffect(()=>{
		setFilmIDS(JSON.parse(localStorage.getItem("film-ids")));
	},[]);
	useEffect(async ()=>{
		let codeList = [];
		for(const filmID in filmIDs){
			codeList.push(await generateQR(`https://www.thelaboratory.film/film-status/${filmIDs[filmID]}`));
		}
		setQRCodes(codeList);

	},[filmIDs]);
	function ImagetoPrint(source){
        return "<html><head><scri"+"pt>function step1(){\n" +
                "setTimeout('step2()', 10);}\n" +
                "function step2(){window.print();window.close()}\n" +
                "</scri" + "pt></head><body onload='step1()'>\n" +
                "<img src='" + source + "' /></body></html>";
    }
  function PrintImage(source)
    {
        var Pagelink = "about:blank";
        var pwa = window.open(Pagelink, "_new");
        pwa.document.open();
        pwa.document.write(ImagetoPrint(source));
        pwa.document.close();
    }

  return (
    <Container>
      <Nav>
      <Link to="/admin">
          <Logo src={redLogo} />
      </Link>
        <NavItems>system name: ip address:</NavItems>
      </Nav>
      <View>
        <TerminalContent>
        {QRCodes.map((code)=> <div><a href={code} download={`${code}.png`}><img key={code} src={code}/></a><br/><Button onClick={()=>PrintImage(code)}>Print</Button><br/><br/></div> )}
        </TerminalContent>
      </View>
    </Container>
  );
}

export default PrintOrder;
