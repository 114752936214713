import { useState,useEffect, useRef } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import styled from "@emotion/styled";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import "./components-styles.css";
import redLogo from "../assets/logo-red.png";

const Container = styled.div`
  height: 100vh;
  width: 100%;
  font-family: Cygnito Mono;
`;
const View = styled.div`
  height: 700px;
  max-width: 100%;
  margin: 25px;
  border-style: solid;
  border-color: #e63731;
  text-align: center;
`;
const Nav = styled.nav`
  padding-top: 30px;
  width: 100%;
  max-width: 1281px;
  margin: auto;
`;
const NavItems = styled.span`
  color: #e63731;
`;
const Logo = styled.img`
  width: 100px;
  max-width: 100%;
`;

const TerminalContent = styled.div`
  background-color: black;
  color: #e63731;
  text-align: left;
  padding-left: 20px;
  font-size: 16px;
`;
const ButtonGroup = styled.div`
  display: flex;
`;
const ButtonSection = styled.div`
  flex: 1;
`;

const Button = styled.button`
  background-color: #e63731;
  font-family: Cygnito Mono;
  font-size: 18px;
  border: none;
  padding: 5px 10px 5px 10px;
  margin-left: 5px;
  cursor: pointer;
`;
const TerminalInput = styled.input`
  background-color: black;
  color: #e63731;
  font-family: Cygnito Mono;
  border: none;
  outline: none; 
  `;
  const DropDownLabel = styled.span`
  position:relative; 
  bottom:8px;
  `;
  const DuplicateButton = styled.button`
  background-color: #e63731;
  font-family: Cygnito Mono;
  font-size: 18px;
  border: none;
  padding: 5px 10px 5px 10px;
  margin-left: 5px;
  cursor: pointer;
  position:relative; 
  bottom:8px;
  `;
function NewProject() {
  const navigate = useNavigate();
  const { userid } = useParams();
  const [films, setFilms] = useState([{filmType:"colour", format:"35mm", size:"medium", tiff:"jpeg",push:"zero", serviceType:"scan-develop"}]);
  const [preferences, setPreferences] = useState({colourTones:"",contrast:"",saturation:""});
  const [studentDiscount, setStudentDiscount] = useState(false);
  const [expressService, setExpressService] = useState('13-16');
  const focusElement = useRef(null);


  function addFilm(){
    setFilms([...films,{filmType:"colour", format:"35mm", size:"medium", tiff:"jpeg",push:"zero"}]);
  }
  function duplicate(index){
    let newFilm = {...films[index]};
    setFilms([...films,{...newFilm}]);
  }
  function changeDropDownValue(index,property,value){
    let tempFilms = [...films];
    let tempFilm = {...films[index]};
    tempFilm[property] = value;
    tempFilms[index] = tempFilm;
    setFilms([...tempFilms]);
  } 
  function changePrefrences(e){
    let tempPrefrences = {...preferences};
    tempPrefrences[e.target.name] = e.target.value;
    setPreferences({...tempPrefrences});
  }
  function saveToLocalStorage(){
    localStorage.setItem("films", JSON.stringify(films));
    localStorage.setItem("preferences", JSON.stringify(preferences));
    localStorage.setItem("studentDiscount", JSON.stringify(studentDiscount));
    localStorage.setItem("expressService", JSON.stringify(expressService));
    navigate(`/admin/submit-order/${userid}`);
  }
  useEffect(() => {
    if (focusElement.current) {
      focusElement.current.focus();
    }
  }, []);
  return (
    <Container>
      <Nav>
        <Link to="/admin">
          <Logo src={redLogo} />
        </Link>
        <NavItems>system name: ip address:</NavItems>
      </Nav>
      <View>
        <TerminalContent>
          Project Name (Optional):
          <TerminalInput ref={focusElement} />
          <br />
          <span onClick={addFilm}><u>+ADD FILM</u></span>
          <br />
          {films.map((film, index)=>{
            return <div key={index}>
              <DropDownLabel>Film Type</DropDownLabel>

          <Dropdown
            options={[
              { label : "B&W", value: "b&w", className: "custom-dropdown-option" },
              { label:"Colour", value: "colour", className: "custom-dropdown-option" },
            ]}
            value={film.filmType.toLowerCase()}
            onChange={(e) => { 
              changeDropDownValue(index,"filmType",e.value)
            }}
            placeholder=""
            className="custom-drop-down"
            controlClassName="custom-drop-down-control-class-name"
            menuClassName="custom-drop-down-menu-class-name"
            required
          />
          <DropDownLabel>Format</DropDownLabel>
          <Dropdown
            options={[
              { label:"35mm - 36", value: "35mm", className: "custom-dropdown-option" },
              { label:"35mm - 24", value: "35mm-24", className: "custom-dropdown-option" },
              { label:"120mm", value: "120mm", className: "custom-dropdown-option" },
            ]}
            value={film.format.toLowerCase()}
            onChange={(e) => { 
              changeDropDownValue(index,"format",e.value)
            }}
            placeholder=""
            className="custom-drop-down"
            controlClassName="custom-drop-down-control-class-name"
            menuClassName="custom-drop-down-menu-class-name"
          />
          <DropDownLabel>Size</DropDownLabel>
          <Dropdown
            options={[
              { label:"M", value: "medium", className: "custom-dropdown-option" },
              { label:"L", value: "large", className: "custom-dropdown-option" },
              { label:"XL", value: "x-large", className: "custom-dropdown-option" },
            ]}
            value={film.size.toLowerCase()}
            onChange={(e) => { 
              changeDropDownValue(index,"size",e.value)
            }}
            placeholder=""
            className="custom-drop-down"
            controlClassName="custom-drop-down-control-class-name"
            menuClassName="custom-drop-down-menu-class-name"
          />
          <DropDownLabel>TIFF</DropDownLabel>
          <Dropdown
            options={[
              { label:"tiff", value: "TIFF", className: "custom-dropdown-option" },
              { label:"jpeg", value: "JPEG", className: "custom-dropdown-option" },
              { label:"DNG", value: "DNG", className: "custom-dropdown-option" },
            ]}
            value={film.tiff.toLowerCase()}
            onChange={(e) => { 
              changeDropDownValue(index,"tiff",e.value)
            }}
            placeholder=""
            className="custom-drop-down"
            controlClassName="custom-drop-down-control-class-name"
            menuClassName="custom-drop-down-menu-class-name"
          />
          <DropDownLabel>Push</DropDownLabel>
          <Dropdown
            options={[
              {label:"+1", value: "+1", className: "custom-dropdown-option" },
              {label:"+2", value: "+2", className: "custom-dropdown-option" },
              {label:"+3", value: "+3", className: "custom-dropdown-option" },
              {label:"Zero",value: "zero", className: "custom-dropdown-option" },
              {abel:"-1", value: "-1", className: "custom-dropdown-option" },
              {label:"-2",value: "-2", className: "custom-dropdown-option" },
              {label:"-3", value: "-3", className: "custom-dropdown-option" },
            ]}
            value={film.push.toLowerCase()}
            onChange={(e) => { 
              changeDropDownValue(index,"push",e.value)
            }}
            placeholder=""
            className="custom-drop-down"
            controlClassName="custom-drop-down-control-class-name"
            menuClassName="custom-drop-down-menu-class-name"
          />
        <Dropdown className="custom-drop-down"
            placeholder=""
            className="custom-drop-down"
            controlClassName="custom-drop-down-control-class-name"
            menuClassName="custom-drop-down-menu-class-name"
            value={film.serviceType.toLowerCase()}
            onChange={(e)=>{
                changeDropDownValue(index,"serviceType", e.value);
            }}
            options={[{label:"Scan and Develop", value:"scan-develop", className:"custom-dropdown-option"},
           {label:"Scan Only", value:"scan-only", className:"custom-dropdown-option"},
           {label:"Develop Only", value:"develop-only", className:"custom-dropdown-option"},
            ]} />
          <DuplicateButton onClick ={()=>duplicate(index)}>Duplicate</DuplicateButton></div>
          })}
          <br />
          <hr />
          {/*   <input type="checkbox" id="prints" name="prints" value="Prints" />
          Prints 8x10 Contact Sheets
          <br />
          <hr />*/}
          Preferences:
          <br />
          <table>
            <tr>
             <td>Colour Tones</td>
              <td><input onChange={(e)=>changePrefrences(e)} id="colour-tones-warm" type="radio" name="colourTones" value="warm" /><label htmlFor="colour-tones-warm">Warm</label> </td>
              <td><input onChange={(e)=>changePrefrences(e)} id="colour-tones-netural" type="radio" name="colourTones" value="netural" /><label htmlFor="colour-tones-netural">Netural</label></td>
              <td><input onChange={(e)=>changePrefrences(e)} id="colour-tones-cold" type="radio" name="colourTones" value="cold" /><label htmlFor="colour-tones-cold">Cold</label></td>
            </tr>
              <tr>
             <td>Contrast</td>
              <td> <input onChange={(e)=>changePrefrences(e)} id="contrast-low" type="radio" name="contrast" value="low" /><label htmlFor="contrast-low">Low</label></td>
              <td><input onChange={(e)=>changePrefrences(e)} id="contrast-netural" type="radio" name="contrast" value="netural" /><label htmlFor="contrast-netural">Netural</label></td>
              <td><input onChange={(e)=>changePrefrences(e)} id="contrast-high" type="radio" name="contrast" value="high" /><label htmlFor="contrast-high">High</label> </td>
            </tr>
              <tr>
             <td>Saturation </td>
              <td> <input onChange={(e)=>changePrefrences(e)} id="saturation-low" type="radio" name="saturation" value="low" /><label htmlFor="saturation-low">Low</label></td>
              <td><input onChange={(e)=>changePrefrences(e)} id="saturation-netural" type="radio" name="saturation" value="neutral" /><label htmlFor="saturation-netural">Netural</label></td>
              <td><input onChange={(e)=>changePrefrences(e)} id="saturation-high" type="radio" name="saturation" value="high" /><label htmlFor="saturation-high">High</label></td>
            </tr>
          </table>
          <br />
          Student : <input type="checkbox" width="60" onChange={(e)=>setStudentDiscount(e.target.checked)}/>
          <br/>
          <DropDownLabel>Delivery</DropDownLabel>
          <Dropdown
            options={[
              {label:"Normal (11-14 business days)", value: "13-16", className: "custom-dropdown-option" },
              {label:"Normal (8-10 business days)", value: "10-12", className: "custom-dropdown-option" },
              {label:"Normal (5-7 business days)", value: "7-9", className: "custom-dropdown-option" },
              {label:"Semi-Express (72 hours)", value: "semi-express", className: "custom-dropdown-option" },
              {label:"Express (24 hours)", value: "express", className: "custom-dropdown-option" },
            ]} 
            value={expressService.toLowerCase()}
            onChange={(e) => {
              setExpressService(e.value);
            }}
            placeholder=""
            className="custom-drop-down big"
            controlClassName="custom-drop-down-control-class-name"
            menuClassName="custom-drop-down-menu-class-name"
          />
          <br/> 
          <br/>
          <Button onClick={saveToLocalStorage}>Next</Button>
        </TerminalContent>
      </View>
    </Container>
  );
}

export default NewProject;
