import React, { useState, useEffect } from 'react';
import { Link, useParams, useNavigate } from "react-router-dom";
import styled from "@emotion/styled";
import { collection, addDoc,updateDoc ,query, where, getDocs, doc, getDoc} from "firebase/firestore"; 
import {db} from "../utils/firebase.js";

import redLogo from "../assets/logo-red.png";

const Container = styled.div`
  height: 100vh;
  width: 100%;
  font-family: Cygnito Mono;
`;
const View = styled.div`
  height: 600px;
  max-width: 100%;
  margin: 25px;
  border-style: solid;
  border-color: #e63731;
  text-align: center;
`;
const Nav = styled.nav`
  padding-top: 30px;
  width: 100%;
  max-width: 1281px;
  margin: auto;
`;
const NavItems = styled.span`
  color: #e63731;
`;
const Logo = styled.img`
  width: 100px;
  max-width: 100%;
`;

const TerminalContent = styled.div`
  background-color: black;
  text-align:center;
  color: #e63731;
  padding-left: 20px;
`;
const ButtonGroup = styled.div`
  display: flex;
`;
const ButtonSection = styled.div`
  flex: 1;
`;

const Button = styled.button`
  background-color: #e63731;
  font-family: Cygnito Mono;
  font-size: 18px;
  border: none;
  padding: 5px 15px 5px 15px;
  margin-left: 5px;
  cursor: pointer;
`;
const TerminalInput = styled.input`
  background-color: black;
  color: #e63731;
  font-family: Cygnito Mono;
  border: none;
`;
const ThankYou = styled.h2`
	margin-top:150px;
	font-size:36px;
	line-height:1;
`;
const OrderPrice = styled.h3`
	margin-top:150px;
	font-size:26px;
	margin-bottom:70px;
`;
const UserLink = styled.a`
  color: #e63731;
  text-decoration:none;
`;


function FilmStatus() {
  const navigate = useNavigate();
	const [filmDetails, setFilmDetails] = useState({});
  const [userDetails, setUserDetails] = useState({});
	const [price, setPrice] = useState("");
	const [films, setFilms] = useState([]);
	const [preferences, setPreferences] = useState({});
	const { filmid } = useParams();


useEffect(()=>{
	const fetchFilmDetails = async () => {
  const docRef = doc(db, "films", filmid);
	const docSnap = await getDoc(docRef);

	if (docSnap.exists()) {
  		setFilmDetails(docSnap.data());
	} else {
 		console.log("No such document!");
	}
  }
	fetchFilmDetails();
	},[filmid]);
useEffect(()=>{
  const fetchUserDetails = async () => {
    if(filmDetails.userId){
    const docRef = doc(db, "users", filmDetails.userId);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      setUserDetails(docSnap.data());
    } else {
      console.log("No such document!");
    }

    }
  }
  fetchUserDetails();
  },[filmDetails.userId]);
  function formatDate(time){
    if(time){
    let date = new Date(time.seconds * 1000);
    return date.toLocaleDateString("en-GB");
  }
  }
  const addDaysToDate=(date, days)=>{
    const month = ["January","February","March","April","May","June","July","August","September","October","November","December"];
		let res = new Date(1970, 0, 1);
    if(date){
      res.setSeconds(date.seconds);
      res.setDate(res.getDate() + days);
      return `${res.getDate()} ${month[res.getMonth()]} ${res.getUTCFullYear()}`;
    }
	}
  return (
    <Container>
      <Nav>
      <Link to="/admin">
          <Logo src={redLogo} />
      </Link>
      </Nav>
      <View>
        <TerminalContent>
        Date: {formatDate(filmDetails.createdAt)||""}<br/>
        Name: { userDetails.name||""}<br/>
        Email: { userDetails.email||""}<br/>
     	  Film Type: {filmDetails.filmType||""}<br/>
     	  Format: {filmDetails.format||""}<br/>
     	  Size: {filmDetails.size||""}<br/>
     	  TIFF: {filmDetails.tiff||""}<br/>
     	  Push: {filmDetails.push||""}<br/>
        Delivery Date: {addDaysToDate(filmDetails.createdAt,filmDetails.expressService) || ""}
        </TerminalContent>
      </View>
    </Container>
  );
}

export default FilmStatus;
