import React, { useState, useEffect } from 'react';
import { Link, useParams, useNavigate } from "react-router-dom";
import styled from "@emotion/styled";
import { collection, addDoc,updateDoc ,query, where, getDocs, doc, getDoc} from "firebase/firestore"; 
import {db} from "../utils/firebase.js";

import redLogo from "../assets/logo-red.png";

const Container = styled.div`
  height: 100vh;
  width: 100%;
  font-family: Cygnito Mono;
`;
const View = styled.div`
  height: 600px;
  max-width: 100%;
  margin: 25px;
  border-style: solid;
  border-color: #e63731;
  text-align: center;
`;
const Nav = styled.nav`
  padding-top: 30px;
  width: 100%;
  max-width: 1281px;
  margin: auto;
`;
const NavItems = styled.span`
  color: #e63731;
`;
const Logo = styled.img`
  width: 100px;
  max-width: 100%;
`;

const TerminalContent = styled.div`
  background-color: black;
  text-align:center;
  color: #e63731;
  padding-left: 20px;
`;
const ButtonGroup = styled.div`
  display: flex;
`;
const ButtonSection = styled.div`
  flex: 1;
`;

const Button = styled.button`
  background-color: #e63731;
  font-family: Cygnito Mono;
  font-size: 18px;
  border: none;
  padding: 5px 15px 5px 15px;
  margin-left: 5px;
  cursor: pointer;
`;
const TerminalInput = styled.input`
  background-color: black;
  color: #e63731;
  font-family: Cygnito Mono;
  border: none;
`;
const ThankYou = styled.h2`
	margin-top:150px;
	font-size:36px;
	line-height:1;
`;
const OrderPrice = styled.h3`
	margin-top:150px;
	font-size:26px;
	margin-bottom:70px;
`;
const UserLink = styled.a`
  color: #e63731;
  text-decoration:none;
`;


function Email() {
  const navigate = useNavigate();
	const [emailAddress, setEmailAddress] = useState("");
	const [price, setPrice] = useState("");
	const [films, setFilms] = useState([]);
	const [preferences, setPreferences] = useState({});
	const { userid } = useParams();


useEffect(()=>{
	const fetchEmailAddress = async () => {
    const docRef = doc(db, "users", userid);
	const docSnap = await getDoc(docRef);

	if (docSnap.exists()) {
  		setEmailAddress(docSnap.data().email);
	} else {
 		console.log("No such document!");
	}
  }
	fetchEmailAddress();
	},[userid]);

  function navigateToPrintScreen(){
     navigate(`/admin/print-order`);
  }


  return (
    <Container>
      <Nav>
      <Link to="/admin">
          <Logo src={redLogo} />
      </Link>
        <NavItems>system name: ip address:</NavItems>
      </Nav>
      <View>
        <TerminalContent>
        <ThankYou>Your order was successfully processed</ThankYou>
        <ThankYou>Please check your email {emailAddress}<br/>for details</ThankYou>
        <Button onClick={navigateToPrintScreen}>Print Qr Code</Button>
        </TerminalContent>
      </View>
    </Container>
  );
}

export default Email;
