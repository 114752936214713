import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate, useParams } from "react-router-dom";
import styled from "@emotion/styled";
import Terminal from "terminal-in-react";
import { collection, addDoc, query, where, getDocs } from "firebase/firestore"; 
import {db} from "../utils/firebase.js"

import redLogo from "../assets/logo-red.png";


const Container = styled.div`
  height: 100vh;
  width: 100%;
  font-family: Cygnito Mono;
`;
const View = styled.div`
  height: 700px;
  max-width: 100%;
  margin: 25px;
  border-style: solid;
  border-color: #e63731;
  text-align: center;
`;
const Nav = styled.nav`
  padding-top: 30px;
  width: 100%;
  max-width: 1281px;
  margin: auto;
`;
const NavItems = styled.span`
  color: #e63731;
`;
const Logo = styled.img`
  width: 100px;
  max-width: 100%;
`;

const TerminalContent = styled.div`
  background-color: black;
  color: #e63731;
  text-align: left;
  padding-left: 20px;
  font-size:1.2rem;
`;
const ButtonGroup = styled.div`
  display: flex;
`;
const ButtonSection = styled.div`
  flex: 1;
`;

const Button = styled.button`
  background-color: #e63731;
  font-family: Cygnito Mono;
  font-size: 28px;
  border: none;
  padding: 15px 45px 15px 45px;
  margin-left: 5px;
  cursor: pointer;
`;
const TerminalInput = styled.input`
  background-color: black;
  color: #e63731;
  font-family: Cygnito Mono;
  border: none;
  outline: none; 
  font-size:1.2rem;
`;



function NewUser() {
    const navigate = useNavigate();
    const focusElement = useRef(null);
    const [userData, setUserData] = useState({
      name:"",
      email:"",
      phone:"",
      cameras:[]
    });
    async function  createUser(userData){
       try {
         const docRef = await addDoc(collection(db, "users"), {
         ...userData
     });
  console.log("Document written with ID: ", docRef.id);
  navigate(`/admin/new-project/${docRef.id}`);
  } catch (e) {
    console.error("Error adding document: ", e);
  }
}
async function checkIfUserAlreadyExists(phone){
  let users = [];
  const citiesRef = collection(db, "users");
  // Create a query against the collection.
  const q =  query(citiesRef, where("phone", "==", phone));
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    console.log(doc);
  // doc.data() is never undefined for query doc snapshots
  users.push(doc.id);
  });
  if(users.length>0){
    return true;
  }
  else{
    return false;
  }
}
  function submitForm(event){
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        //TODO:Create user
        if(!checkIfUserAlreadyExists(userData.phone)){
           let confirmMessage = window.confirm("User already exists, navigate to existing user page?");
               if( confirmMessage == true ) {
                    navigate("/existing-user");
               } 
        }
        else{
            createUser(userData);
          }
        console.log("Enter key was pressed. Run your function.");
        event.preventDefault();
      }
}
  function formatCameras(str){
    const cameras = str.split(";").map(function(item) {
      return item.trim(" ");
     });
    return cameras;
}
  useEffect(() => {
    if (focusElement.current) {
      focusElement.current.focus();
    }
  }, []);
  
  return (
    <Container>
      <Nav>
      <Link to="/admin">
          <Logo src={redLogo} />
      </Link>
        <NavItems>system name: ip address:</NavItems>
      </Nav>
      <View>
        <TerminalContent>
          Welcome to the laboratory!!!
          <br />
          <form >
            Name:
          <TerminalInput onChange={(e)=>{setUserData({...userData,name:e.target.value})}} ref={focusElement} />
          <br />
          Email:
          <TerminalInput onChange={(e)=>{setUserData({...userData,email:e.target.value})}}/>
          <br />
          Phone:
          <TerminalInput onChange={(e)=>{setUserData({...userData,phone:e.target.value})}}/>
          <br />
          Cameras:
          <TerminalInput onKeyPress={(e)=>submitForm(e)} onChange={
            (e)=>{
            setUserData({...userData, cameras: formatCameras(e.target.value)})
          }}/>
          </form>
    
          <br />
          <span>Press Enter to proceed</span>
        </TerminalContent>

        {/*  <Terminal
          color="#e63731"
          backgroundColor="black"
          barColor="black"
          prompt="#e63731"
          hideTopBar={true}
          allowTabs={false}
          style={{
            fontFamily: " Cygnito Mono",
            fontWeight: "bold",
            fontSize: "1em",
          }}
          commands={{
            "open-google": () =>
              window.open("https://www.google.com/", "_blank"),
            showmsg: showmsg,
            popup: () => alert("Terminal in React"),
          }}
          descriptions={{
            "open-google": "opens google.com",
            showmsg: "shows a message",
            alert: "alert",
            popup: "alert",
          }}
          msg="Hello! Welcome to the laboratory!!!!"
        />*/}
      </View>
    </Container>
  );
}

export default NewUser;
