import React, { useState, useEffect, useRef } from 'react';
import { Link } from "react-router-dom";
import styled from "@emotion/styled";
import { collection, addDoc ,query, where, getDocs} from "firebase/firestore"; 
import {db} from "../utils/firebase.js";

import redLogo from "../assets/logo-red.png";

const Container = styled.div`
  height: 100vh;
  width: 100%;
  font-family: Cygnito Mono;
`;
const View = styled.div`
  height: 700px;
  max-width: 100%;
  margin: 25px;
  border-style: solid;
  border-color: #e63731;
  text-align: center;
`;
const Nav = styled.nav`
  padding-top: 30px;
  width: 100%;
  max-width: 1281px;
  margin: auto;
`;
const NavItems = styled.span`
  color: #e63731;
`;
const Logo = styled.img`
  width: 100px;
  max-width: 100%;
`;

const TerminalContent = styled.div`
  background-color: black;
  color: #e63731;
  text-align: left;
  padding-left: 20px;
  font-size:1.2rem;
`;
const ButtonGroup = styled.div`
  display: flex;
`;
const ButtonSection = styled.div`
  flex: 1;
`;

const Button = styled.button`
  background-color: #e63731;
  font-family: Cygnito Mono;
  font-size: 28px;
  border: none;
  padding: 15px 45px 15px 45px;
  margin-left: 5px;
  cursor: pointer;
`;
const TerminalInput = styled.input`
  background-color: black;
  color: #e63731;
  font-family: Cygnito Mono;
  border: none;
  outline: none;
  font-size:1.2rem; 
`;
const UserLink = styled.a`
  color: #e63731;
  text-decoration:none;
`;


function ExistingUser() {
  const [phone, setPhone] = useState("");
  const [users, setUsers] = useState([]);
  const focusElement = useRef(null);

  async function searchUsersByPhoneNumber(phoneNumber){
  const searchedUsers = [];

  const usersRef = collection(db, "users");
  const q = query(usersRef, where("phone", "==", phoneNumber));
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    searchedUsers.push({id:doc.id,data:doc.data()})
});
  console.log(searchedUsers);
  setUsers(searchedUsers)
}
  function checkPhoneNumber(event){
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        searchUsersByPhoneNumber(event.target.value);
      }
  }
  useEffect(() => {
    if (focusElement.current) {
      focusElement.current.focus();
    }
  }, []);
  return (
    <Container>
      <Nav>
      <Link to="/admin">
          <Logo src={redLogo} />
      </Link>
        <NavItems>system name: ip address:</NavItems>
      </Nav>
      <View>
        <TerminalContent>
          Welcome to the laboratory!!!
          <br />
          Phone:
          <TerminalInput onChange={(e)=> setPhone(e.target.value)} onKeyPress={(e)=>checkPhoneNumber(e)}ref={focusElement} />
          <br />
          <span>
          <ul>
            {users.map((user)=>{
             return <UserLink key={user.id} href={`/admin/new-project/${user.id}`}><li>Name: {user.data.name}, email: {user.data.email}</li></UserLink>
            })}
          </ul>
          </span>
          <span>Press Enter to proceed</span>
        </TerminalContent>
      </View>
    </Container>
  );
}

export default ExistingUser;
