import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Login from "./routes/Login";
import NewUser from "./routes/NewUser";
import ExistingUser from "./routes/ExistingUser";
import NewProject from "./routes/NewProject";
import SubmitOrder from "./routes/SubmitOrder";
import Email from "./routes/Email";
import PrintOrder from "./routes/PrintOrder.js";
import FilmStatus from "./routes/FilmStatus.js";

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
      <Route path="/admin" element={<App />} />
        <Route path="/login" element={<Login />} />
        <Route path="/admin/new-user" element={<NewUser />} />
        <Route path="/admin/existing-user" element={<ExistingUser />} />
        <Route path="/admin/new-project/:userid" element={<NewProject />} />
        <Route path="/admin/submit-order/:userid" element={<SubmitOrder />} />
        <Route path="/admin/email/:userid" element={<Email />} />
        <Route path="/admin/print-order" element={<PrintOrder />} />
        <Route path="/film-status/:filmid" element={<FilmStatus />} />
      </Routes>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
