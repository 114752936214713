import { Link } from "react-router-dom";
import styled from "@emotion/styled";

import redLogo from "./assets/logo-red.png";

const Container = styled.div`
  height: 100vh;
  width: 100%;
  font-family: Cygnito Mono;
`;
const View = styled.div`
  height: 600px;
  max-width: 100%;
  margin: 25px;
  border-style: solid;
  border-color: #e63731;
  text-align: center;
`;
const VerticalCenterView = styled.div` 
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  `;
const Nav = styled.nav`
  padding-top: 30px;
  width: 100%;
  max-width: 1281px;
  margin: auto;
`;
const NavItems = styled.span`
  color: #e63731;
`;
const Logo = styled.img`
  width: 100px;
  max-width: 100%;
`;
const WelcomeMessage = styled.h2`
  color: #e63731;
  font-size: 37px;
`;
const ButtonGroup = styled.div`
  display: flex;
`;
const ButtonSection = styled.div`
  flex: 1;
`;

const Button = styled.button`
  background-color: #e63731;
  font-family: Cygnito Mono;
  font-size: 22px;
  border: none;
  padding: 15px 45px 15px 45px;
  margin-left: 5px;
  cursor: pointer;
`;

function App() {
  return (
    <Container>
      <Nav>
      <Link to="/">
          <Logo src={redLogo} />
      </Link>
        <NavItems>system name: ip address:</NavItems>
      </Nav>
      <View>
      <VerticalCenterView>
        <WelcomeMessage>Welcome to the Laboratory</WelcomeMessage>
        <ButtonGroup>
          <ButtonSection>
            <Link to="/admin/new-user">
              <Button>New User</Button>
            </Link>
          </ButtonSection>
          <ButtonSection>
            <Link to="/admin/existing-user">
              <Button>Existing User</Button>
            </Link>
          </ButtonSection>
        </ButtonGroup>
        </VerticalCenterView>
      </View>
    </Container>
  );
}

export default App;
